





















































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import Card from '@/components/Card.vue'
import ProductCachePanel from '@/views/AdminDash/vues/ProductCachePanel.vue'
import TwilioApplicationPanel from '@/views/AdminDash/vues/TwilioApplicationPanel.vue'
import EmailSuppressionPanel from '@/views/AdminDash/vues/EmailSuppressionPanel.vue'
import StripeBalanceTransactionPanel from '@/views/AdminDash/vues/StripeBalanceTransactionPanel.vue'
import { canViewStripeReport } from '@/models/UpwardRunning/Auth/UpwardRunningCredentials'
import store from '@/store'

export default defineComponent({
  components: {
    Card,
    ProductCachePanel,
    TwilioApplicationPanel,
    EmailSuppressionPanel,
    StripeBalanceTransactionPanel,
  },

  setup(p, ctx) {
    const disabled = ref(false)
    // quick guard of this component.
    onMounted(() => {
      if (!store.getters.authorization.isCurrentUserAnAdmin) {
        disabled.value = true
      }
    })

    const isUserReallyASuperAdmin = computed(() => store.getters.authorization.isCurrentUserASuperAdmin)

    const canRunStripeReport = computed(() => {
      const urCreds = store.getters.upwardRunningAuth.urCreds
      return urCreds != null && canViewStripeReport(urCreds)
    })

    async function impersonate(payload: { userName?: string; accountNumber: string }) {
      await store.dispatch.authorization.impersonate(payload)
      await ctx.root.$router.push('/')
    }
    return { disabled, impersonate, isUserReallyASuperAdmin, canRunStripeReport }
  },
})
