import { League } from '@/GeneratedTypes/League'
import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'

export type LeagueIDType = number
export type UpwardLeagueIDType = string

export function getEmptyLeague(): League {
  return {
    productOfferingConfig: null,
    firstEvalDate: new Date(),
    evalDescription: '',
    firstPracticeDate: new Date(),
    firstGameDate: new Date(),
    seasonEndDate: new Date(),
    leagueDirectorPartnerID: 0,
    allowCarpoolLinks: false,
    carPoolLinkLabelOverride: '',
    allowCoachLinks: false,
    allowPracticeNightExclusions: false,
    allowAssistantCoachLinks: false,
    id: 0,
    typeLeagueID: '',
    typeProductGroupID: '',
    leagueName: '',
    publicDisplayName: '',
    upwardLeagueID: '',
    registrationBeginDate: new Date(),
    registrationEndDate: new Date(),
    lateDate: new Date(),
    typeSystemsAccessID: '',
    isPrivate: false,
    bailedReason: '',
    isMailIn: false,
    isOPRLeague: false,
    otherRegistrationVendorName: '',
    leagueRegistrationURL: '',
    typeCountryID: 'US',
    leagueFinderMessage: '',
    programMissionObjective: '',
    typeTimezoneID: '',
    typeDevotionTrackID: 'VIRTUES',
    awardsOrderDate: new Date(),
    hideTeamPageRoster: false,
    hideCoachEmailOnTeamPage: false,
    hideCoachPhoneOnTeamPage: false,
    hideAssistantCoachEmailOnTeamPage: true,
    hideAssistantCoachPhoneOnTeamPage: true,
    hideScheduleOnTeamPage: false,
    hidePracticeScheduleOnTeamPage: false,
    defaultTypePaymentMethodID: '',
    useInternationalDateFormat: false,
    fromEmailAddress: '',
    fromEmailDisplayName: '',
    registrationInfo: null,
    accounts: [],
    programs: [],
    practiceNights: null,
    playerUDFs: null,
    roles: null,
    partnerContact: getEmptyContact(),
    canEdit: true,
    canEditReason: null,
    optOutFanGear: false,
    leagueFinderURLOverride: null,
    requestRiskMitigationInfo: false,
    selectSportName: null,
    hideRosterOnSportsApp: false,
    hideScheduleOnSportsApp: false,
    hidePracticeScheduleOnSportsApp: false,
    ageCutoffDate: null,
    gradeDescriptions: [],
    hideHeadCoachLabel: false,
  }
}
