




























































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import OpportunityTypeSelect from '@/components/OpportunityTypeSelect.vue'
import LeagueTypeSelect from '@/components/LeagueTypeSelect.vue'
import FullBody from '@/components/FullBody.vue'
import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'
import ReadFirstModal from '@/views/Programs/ReadFirstModal.vue'
import PageContent from '@/components/PageContent.vue'
import { UpwardOpportunityTypesByUpwardTypeID } from '@/lib/common/upwardOpportunityTypes'
import { UpwardOpportunityTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardOpportunityTypeID'
import store from '@/store'
import { canCreatePartnerChallenge } from '@/models/UpwardRunning/Auth/UpwardRunningCredentials'

export default defineComponent({
  name: 'New',
  components: {
    FullBody,
    TextInput,
    LeagueTypeSelect,
    OpportunityTypeSelect,
    Loading,
    ReadFirstModal,
    PageContent,
  },
  setup(props, ctx) {
    const accountName = computed(() => store.getters.authorization.accountName)
    const isSystemsAndSupport = computed(() => store.getters.authorization.isSystemsAndSupport)
    const allOpportunities = computed<UpwardOpportunityTypeID[]>(
      () => store.getters.opportunityTypes.allItems
    )

    const loading = ref(false)
    const name = ref('')
    const leagueTypeId = ref<number | null>(null)
    const opportunityTypeId = ref<string | null>(null)
    const showModal = ref(false)

    onMounted(async () => {
      await store.dispatch.upwardRunningAuth.getURCreds()
    })

    const cmsDocumentId = computed(() => {
      if (opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.LEAGUE) {
        return 'programTypeDescLeague'
      } else if (opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.CAMP) {
        return 'programTypeDescCamp'
      } else if (opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.CLINIC) {
        return 'programTypeDescClinic'
      } else if (opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.CONNECT) {
        return 'programTypeDescConnect'
      } else if (opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.UPWARDSELECT) {
        return 'programTypeDescUpwardSelect'
      } else if (opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.UPWARDRUNNING) {
        return 'programTypeDescUpwardRunning'
      }
      return ''
    })

    const leagueType = computed(() => {
      if (opportunityTypeId.value && leagueTypeId.value) {
        return store.getters.leagueTypes.byId(leagueTypeId.value)
      }

      return null
    })

    const opportunityType = computed(() => {
      if (opportunityTypeId.value) {
        return allOpportunities.value.find((ot) => ot.upwardTypeID === opportunityTypeId.value)
      }

      return null
    })

    const isLeague = computed(() => {
      return (
        opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.LEAGUE ||
        opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.UPWARDSELECT ||
        !opportunityTypeId.value
      )
    })

    const isLicense = computed(() => {
      return (
        isSystemsAndSupport.value &&
        (opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.LICENSING ||
          !opportunityTypeId.value)
      )
    })

    const isConnect = computed(() => {
      return (
        opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.CONNECT || !opportunityTypeId.value
      )
    })

    const isRunning = computed(() => {
      return (
        opportunityTypeId.value === UpwardOpportunityTypesByUpwardTypeID.UPWARDRUNNING ||
        !opportunityTypeId.value
      )
    })

    const allowContinue = computed(() => {
      if (isLeague.value) {
        return !!opportunityTypeId.value && !!leagueTypeId.value
      } else if (isRunning.value) {
        const urCreds = store.getters.upwardRunningAuth.urCreds
        return (
          urCreds != null &&
          canCreatePartnerChallenge(urCreds) &&
          !!opportunityTypeId.value &&
          !!leagueTypeId.value &&
          !!name.value
        )
      } else {
        return !!opportunityTypeId.value && !!leagueTypeId.value && !!name.value
      }
    })

    const showRunningWarning = computed(() => {
      //const urCreds = store.getters.upwardRunningAuth.urCreds
      //return isRunning.value && (urCreds == null || !canCreatePartnerChallenge(urCreds))
      return false //I think the current verbiage eliminates the need for this warning.
    })

    function readFirstConfirmed(confirmed: boolean) {
      if (confirmed) {
        ctx.root.$router.push('/programs/welcome')
      }
    }

    async function saveAndContinue() {
      if (!leagueType.value || !opportunityType.value) {
        return
      }
      store.commit.creationParameters.setOpportunityType({ opportunityType: opportunityTypeId.value })
      store.commit.creationParameters.setLeagueType({ leagueType: leagueType.value.upwardTypeID! })

      if (isConnect.value) {
        await store.dispatch.connects.beginCreating({
          name: name.value,
          typeLeagueID: leagueType.value.upwardTypeID!,
          partnerContact: null,
        })
        ctx.root.$router.push('/programs/connect/setup/information')
      } else if (leagueType.value.isClinic) {
        await store.dispatch.clinics.beginCreating({
          name: name.value,
          typeLeagueID: leagueType.value.upwardTypeID!,
          partnerContact: null,
        })
        ctx.root.$router.push('/programs/clinic/setup/information')
      } else if (leagueType.value.isCamp) {
        loading.value = true
        await store.dispatch.camps.beginCreating({
          name: name.value,
          typeLeagueID: leagueType.value.upwardTypeID!,
          publicDisplayName: accountName.value!,
          partnerContact: null,
        })
        ctx.root.$router.push('/programs/camp/setup/information')
        loading.value = false
      } else if (isLeague.value && leagueTypeId.value) {
        // League is created after picking between league and estimate
        // See ProgramCreationPicker.vue
        store.commit.leagues.setStartingSelection({
          selection: {
            name: store.getters.leagueTypes.byId(leagueTypeId.value).description || 'New Program',
            leagueTypeId: leagueTypeId.value,
            opportunityTypeId: opportunityType.value.id,
            upwardTypeId: leagueType.value.upwardTypeID ?? '',
          },
        })
        showModal.value = true
      } else if (isLicense.value && leagueTypeId.value) {
        //LICENSE
        loading.value = true
        name.value = store.getters.leagueTypes.byId(leagueTypeId.value).description || 'New License'

        await store.dispatch.leagues.beginCreating({
          name: name.value,
          typeLeagueID: leagueType.value.upwardTypeID!,
          publicDisplayName: accountName.value!,
          partnerContact: null,
          addToCache: true,
        })
        ctx.root.$router.push('/programs/league/setup/information')
        loading.value = false
      } else if (isRunning.value) {
        await store.dispatch.upwardRunning.beginCreating({
          name: name.value,
          typeLeagueID: leagueType.value.upwardTypeID!,
          publicDisplayName: accountName.value!,
          addToCache: false,
        })
        ctx.root.$router.push('/programs/running/setup/information')
      }
    }

    return {
      opportunityTypeId,
      leagueTypeId,
      isLeague,
      name,
      loading,
      allowContinue,
      cmsDocumentId,
      showModal,
      readFirstConfirmed,
      saveAndContinue,
      showRunningWarning,
    }
  },
})
