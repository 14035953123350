import { LeagueDivisionStandingInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionStandingInfo'

export const divisionStandings = (list: LeagueDivisionStandingInfo[]) => {
  // Sort the list by Win Percentage (descending) and then teamName (ascending)
  list.sort((a, b) => {
    const wpctA = (a.teamWins + 0.5 * a.teamTies) / (a.teamWins + a.teamLosses + a.teamTies)
    const wpctB = (b.teamWins + 0.5 * b.teamTies) / (b.teamWins + b.teamLosses + b.teamTies)

    if (wpctA !== wpctB) {
      return wpctB - wpctA // Descending order based on WPCT
    }
    if (a.teamWins !== b.teamWins) {
      return b.teamWins - a.teamWins // Descending order based on total Wins
    }
    return (a.teamName || '').localeCompare(b.teamName || '') // Alphabetical tiebreaker
  })

  // Directly map the sorted list to the desired format
  const standings: divisionStanding[] = list.map((item) => ({
    teamID: item.teamID,
    teamName: item.teamName,
    teamWins: item.teamWins,
    teamLosses: item.teamLosses,
    teamTies: item.teamTies,
    upwardTeamID: item.upwardTeamID,
  }))

  return standings
}

export interface divisionStanding {
  teamID: number
  teamName: string | null
  upwardTeamID: string | null
  teamWins: number
  teamLosses: number
  teamTies: number
}
