import restServiceUR from '@/services/restServiceUR'
import { PartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'
import { PartnerChallengeInfo } from '@/models/UpwardRunning/PartnerChallengeInfo'
import { UpwardRunningAuthToken } from '@/models/UpwardRunning/Auth//UpwardRunningAuthToken'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import { PartnerChallengeParticipantInfo } from '@/models/UpwardRunning/PartnerChallengeParticipantInfo'
import store from '@/store'
import { BalanceTransactionRequest } from '@/models/UpwardRunning/BalanceTransactionRequest'
import { BalanceTransactionReportResponse } from '@/models/UpwardRunning/BalanceTransactionReportResponse'

const baseUrl = 'PartnerChallenge/'

const create = async (challenge: PartnerChallenge) => {
  await store.dispatch.upwardRunningAuth.getURCreds()
  return await restServiceUR.post<PartnerChallenge>(`${baseUrl}`, challenge)
}

const update = async (id: string, challenge: PartnerChallenge) => {
  await store.dispatch.upwardRunningAuth.getURCreds()
  return await restServiceUR.put<PartnerChallenge>(`${baseUrl}${id}`, challenge)
}

const retrieve = async (id: string) => {
  await store.dispatch.upwardRunningAuth.getURCreds()
  return await restServiceUR.get<PartnerChallenge>(`${baseUrl}${id}`)
}

const retrieveParticipants = async (id: string) => {
  await store.dispatch.upwardRunningAuth.getURCreds()
  return await restServiceUR.get<PartnerChallengeParticipantInfo[]>(`${baseUrl}info/${id}/participants`)
}

const validateNew = async (challenge: PartnerChallenge, ruleSet = '') => {
  await store.dispatch.upwardRunningAuth.getURCreds()
  return await restServiceUR.post<UpwardVerificationDetails>(`${baseUrl}verificationDetails`, challenge, {
    params: {
      ruleSet,
    },
  })
}

const validateExisting = async (id: string, challenge: PartnerChallenge) => {
  await store.dispatch.upwardRunningAuth.getURCreds()
  return await restServiceUR.post<UpwardVerificationDetails>(`${baseUrl}${id}/verificationDetails`, challenge)
}

const loginWithLegacyToken = async (userData: string) => {
  try {
    const t = await restServiceUR.post<UpwardRunningAuthToken>(`auth/loginwithlegacytoken`, {
      userToken: userData,
    })
    if (t.status == 200) {
      return t.data as UpwardRunningAuthToken
    }
  } catch (e) {
    return null
  }
}

const retrieveAll = async (accountNumber: string) => {
  await store.dispatch.upwardRunningAuth.getURCreds()
  return await restServiceUR.get<PartnerChallengeInfo[]>(`${baseUrl}listForAccount/${accountNumber}`)
}

const retrieveBalanceTransactionReport = async (request: BalanceTransactionRequest) => {
  await store.dispatch.upwardRunningAuth.getURCreds()
  const results = await restServiceUR.post<BalanceTransactionReportResponse[]>(
    'stripe/admin/balanceTransactions',
    request
  )

  return results.data
}

export default {
  create,
  retrieve,
  update,
  validateNew,
  validateExisting,
  loginWithLegacyToken,
  retrieveAll,
  retrieveParticipants,
  retrieveBalanceTransactionReport,
}
