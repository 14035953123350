export interface UpwardRunningCredentials {
  displayName: string
  emailAddress: string
  expiration: Date | null
  activities: string
}

export function canCreatePartnerChallenge(creds: UpwardRunningCredentials) {
  return (creds.activities ?? '').includes('PartnerChallenge.Create')
}

export function canViewStripeReport(creds: UpwardRunningCredentials) {
  return (creds.activities ?? '').includes('Stripe.Get')
}
