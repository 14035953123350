import { defineMutations, defineActions, defineGetters } from 'direct-vuex'
import { Commit } from 'vuex'

import { PartnerChallenge, getEmptyPartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'
import { PartnerChallengeStartupProductConfig } from '@/models/UpwardRunning/PartnerChallengeStartupProductConfig'
import { PartnerChallengeProductConfig } from '@/models/UpwardRunning/PartnerChallengeProductConfig'
import { RootContact, getEmptyRootContact, ContactInfoToRootContact } from '@/models/Partner/RootContact'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import sessionObjectsClient from '@/clients/sessionObjectsClient'
import upwardRunningClient from '@/clients/upwardRunningClient'
import upwardRunningOrderClient from '@/clients/upwardRunningOrderClient'
import { uniqueUserIdCheck } from '@/store/helper'
import store, { moduleActionContext } from '@/store'
import partnersClient from '@/clients/partnersClient'
import { PartnerChallengeParticipantInfo } from '@/models/UpwardRunning/PartnerChallengeParticipantInfo'
import { PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import { DataTablePagination, getEmptyDataTablePagination } from '@/models/DataTable/DataTableSelection'
import communicationsClient from '@/clients/communicationsClient'

export const NEW_PARTICIPANT = -1
export const NO_ACTIVE_PARTICIPANT = -2

class ParticipantRuntimeException extends Error {
  public innerException: Error | undefined = undefined
  constructor(message: string, exception?: Error) {
    super(message)
    this.message = message
    this.name = 'Participant Runtime Exception'
    this.innerException = exception
  }
}

class ParticipantException extends Error {
  public innerException: Error | undefined = undefined
  constructor(message: string, exception?: Error) {
    super(message)
    this.message = message
    this.name = 'ParticipantException'
    this.innerException = exception
  }
}

function throwError(message: string, commit?: Commit, error?: Error) {
  commit && commit(mutationNames.setError, { message, error })
  throw new ParticipantException(message, error)
}
interface UpwardRunningState {
  currentChallenge: PartnerChallenge
  currentPartnerContact: RootContact
  currentParticipant?: PartnerChallengeParticipantInfo
  runningOrderProducts: RunningOrderProductInfo[]
  participants: PartnerChallengeParticipantInfo[]
  lastError: string
  lastException: Error | null
  participantUnderEditId: PlayerInfoIDType
  pagination: DataTablePagination
  loading: boolean
}

export interface UpwardRunningCache {
  challenge: PartnerChallenge
  contact: RootContact
}

const upwardRunningState: UpwardRunningState = {
  currentChallenge: getEmptyPartnerChallenge(),
  currentPartnerContact: getEmptyRootContact(),
  currentParticipant: undefined,
  runningOrderProducts: [],
  participants: [],
  lastError: '',
  lastException: null,
  participantUnderEditId: NO_ACTIVE_PARTICIPANT,
  pagination: getEmptyDataTablePagination(),
  loading: false,
}

export enum getterNames {
  currentParticipant = 'currentParticipant',
  currentChallenge = 'currentChallenge',
  currentPartnerContact = 'currentPartnerContact',
  runningOrderProducts = 'runningOrderProducts',
  participants = 'participants',
  pagination = 'pagination',
  loading = 'loading',
}

const getterTree = defineGetters<UpwardRunningState>()({
  currentParticipant: (state) => state.currentParticipant,
  currentChallenge: (state) => state.currentChallenge,
  currentPartnerContact: (state) => state.currentPartnerContact,
  runningOrderProducts: (state) => state.runningOrderProducts,
  participants: (state) => state.participants,
  pagination: (state) => state.pagination,
  loading: (state) => state.loading,
})

export enum mutationNames {
  setCurrentParticipant = 'setCurrentParticipant',
  setCurrentChallenge = 'setCurrentChallenge',
  setCurrentPartnerContact = 'setCurrentPartnerContact',
  setRunningOrderProducts = 'setRunningOrderProducts',
  setChallengeStartupConfig = 'setChallengeStartupConfig',
  setChallengeProductConfig = 'setChallengeProductConfig',
  setChallengeParticipantEstimate = 'setChallengeParticipantEstimate',
  setChallengeEarlyRegistrationFee = 'setChallengeEarlyRegistrationFee',
  setChallengeRegistrationFee = 'setChallengeRegistrationFee',
  clearCurrent = 'clearCurrent',
  setParticipants = 'setParticipants',
  setError = 'setError',
  setPagination = 'setPagination',
  clearCurrentParticipant = 'clearCurrentParticipant',
  setLoading = 'setLoading',
}
const mutations = defineMutations<UpwardRunningState>()({
  setCurrentParticipant(state, { participant }: { participant: PartnerChallengeParticipantInfo }) {
    if (participant === undefined) {
      throw new ParticipantRuntimeException('participant undefined, check arguments.')
    }
    state.currentParticipant = participant
  },
  setCurrentChallenge(state, { challenge }: { challenge: PartnerChallenge }) {
    state.currentChallenge = challenge
  },
  setCurrentPartnerContact(state, { contact }: { contact: RootContact }) {
    state.currentPartnerContact = contact
  },
  setRunningOrderProducts(state, { products }: { products: RunningOrderProductInfo[] }) {
    state.runningOrderProducts = products
  },
  setChallengeStartupConfig(state, { products }: { products: PartnerChallengeStartupProductConfig[] }) {
    state.currentChallenge.startupProductConfig = products
  },
  setChallengeProductConfig(state, { products }: { products: PartnerChallengeProductConfig[] }) {
    state.currentChallenge.productConfig = products
  },
  setChallengeParticipantEstimate(state, { est }: { est: number }) {
    state.currentChallenge.participantEstimate = est
  },
  setChallengeEarlyRegistrationFee(state, { fee }: { fee: number }) {
    state.currentChallenge.earlyRegistrationFee = fee
  },
  setChallengeRegistrationFee(state, { fee }: { fee: number }) {
    state.currentChallenge.registrationFee = fee
  },
  clearCurrent(state) {
    state.currentChallenge = getEmptyPartnerChallenge()
    state.currentPartnerContact = getEmptyRootContact()
  },
  setParticipants(state: UpwardRunningState, { items }: { items: PartnerChallengeParticipantInfo[] }) {
    if (items === undefined) {
      throw new ParticipantRuntimeException('items undefined, check arguments.')
    }
    state.participants = items
  },
  setError(state, { error, exception }: { error: string; exception?: Error }) {
    if (error === undefined) {
      throw new ParticipantRuntimeException('Error undefined, check arguments.')
    }
    //@todo - propagate to general error -
    state.lastError = error
    state.lastException = exception || null
    console.warn('participant store', error, exception)
  },
  setPagination(state, { pagination }: { pagination: DataTablePagination }) {
    state.pagination = pagination
  },
  clearCurrentParticipant(state) {
    state.currentParticipant = undefined
  },
  setLoading(state, loading: boolean) {
    state.loading = loading
  },
})

export enum actionNames {
  setCurrentParticipantByID = 'setCurrentParticipantByID',
  beginCreating = 'beginCreating',
  cache = 'cache',
  loadFromCache = 'loadFromCache',
  validate = 'validate',
  update = 'update',
  create = 'create',
  loadRunningOrderProducts = 'loadRunningOrderProducts',
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  loadRunningParticipants = 'loadRunningParticipants',
}

const actions = defineActions({
  async create(
    { commit, rootGetters },
    { item }: { item: PartnerChallenge }
  ): Promise<PartnerChallenge | null> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)
    //first save the contact and assign the DI if needed.
    const storeContact: RootContact = rootGetters[`${namespace}/${getterNames.currentPartnerContact}`]
    const savedContact = storeContact.id
      ? await partnersClient.updateAccountContact(item.accountNumber ?? '', storeContact.id, storeContact)
      : await partnersClient.createAccountContact(item.accountNumber ?? '', storeContact)
    item.coachPartnerContactID = savedContact?.id ?? 0
    commit(mutationNames.setCurrentPartnerContact, { contact: savedContact })
    const result = await upwardRunningClient.create(item)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrentChallenge, { challenge: result.data })
      await sessionObjectsClient.delete(`running.new.${uniqueUserId}`)
      await communicationsClient.setupURComms(result.data.upwardChallengeID!) //if we get here then this ID is not null
      return result.data
    }

    return null
  },
  async retrieveAndSetAsCurrent({ commit }, { id }: { id: string }): Promise<PartnerChallenge | null> {
    const result = await upwardRunningClient.retrieve(id)

    if (result) {
      const contact = await partnersClient.retrieveAccountContact(
        result.data?.accountNumber ?? '',
        result.data?.coachPartnerContactID ?? 0
      )
      commit(mutationNames.setCurrentPartnerContact, { contact })
      commit(mutationNames.setCurrentChallenge, { challenge: result.data })
    }

    return null
  },
  async update({ commit }, { challenge }: { challenge: PartnerChallenge }): Promise<PartnerChallenge | null> {
    const result = await upwardRunningClient.update(challenge.upwardChallengeID!, challenge)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrentChallenge, { challenge: result.data })
      return result.data
    }

    return null
  },
  async validate(
    { commit },
    { item, ruleSet = '' }: { item: PartnerChallenge; ruleSet: string }
  ): Promise<boolean> {
    const isNew = !item.upwardChallengeID

    const validationResult = await (isNew
      ? upwardRunningClient.validateNew(item, ruleSet)
      : upwardRunningClient.validateExisting(item.upwardChallengeID!, item))

    if (!validationResult.isSuccess) {
      return false
    }

    commit(mutationNames.setCurrentChallenge, { challenge: validationResult.data!.model })
    return true
  },
  async cache(
    { commit, rootGetters },
    { challenge, contact }: { challenge: PartnerChallenge; contact: RootContact }
  ): Promise<boolean> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)

    const isNew = !challenge.upwardChallengeID
    const cacheKey = `running.${isNew ? `new.${uniqueUserId}` : challenge.upwardChallengeID}`
    const cacheResult = await sessionObjectsClient.createOrUpdate(cacheKey, {
      challenge: challenge,
      contact: contact,
    })

    if (!cacheResult.isSuccess) {
      return false
    }

    commit(mutationNames.setCurrentChallenge, { challenge })
    commit(mutationNames.setCurrentPartnerContact, { contact })
    return true
  },
  async loadFromCache({ commit, rootGetters }): Promise<boolean> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)

    const result = await sessionObjectsClient.retrieve<UpwardRunningCache>(`running.new.${uniqueUserId}`)

    if (!result.isSuccess) {
      return false
    }

    const cached = result.data!

    commit(mutationNames.setCurrentChallenge, { challenge: cached.challenge })
    commit(mutationNames.setCurrentPartnerContact, { contact: cached.contact })

    return true
  },
  async beginCreating(
    { commit, dispatch },
    {
      name,
      typeLeagueID,
      publicDisplayName,
      addToCache,
    }: {
      name: string
      typeLeagueID: string
      publicDisplayName: string
      addToCache: boolean
    }
  ): Promise<boolean> {
    const challenge = getEmptyPartnerChallenge()
    let contact: RootContact | undefined = undefined

    //see if this person is already a contact on this account
    const contactsResult = await partnersClient.retrieveAccountContactsFullList(
      store.getters.authorization.activeAccount ?? undefined
    )

    console.log('TNT beginCreating.... result', contactsResult)

    if (contactsResult.isSuccess) {
      const matchedContact = contactsResult.data?.find(
        (x) => x.emailAddress == store.getters.upwardRunningAuth.urCreds?.emailAddress
      )
      if (matchedContact) {
        contact = ContactInfoToRootContact(matchedContact)
      }
    }

    console.log('TNT beginCreating... contact', contact)

    if (!contact) {
      console.log('TNT beginCreating... empty contact')
      contact = getEmptyRootContact()
      contact.emailAddress = store.getters.upwardRunningAuth.urCreds?.emailAddress ?? ''
      const nameParts = store.getters.upwardRunningAuth.urCreds?.displayName?.split(' ')
      contact.firstName = nameParts?.shift() ?? ''
      contact.lastName = nameParts?.join(' ') ?? ''
    }

    challenge.challengeName = name
    challenge.publicDisplayName = publicDisplayName
    challenge.typeLeagueID = typeLeagueID
    challenge.accountNumber = store.getters.authorization.activeAccount

    commit(mutationNames.setCurrentChallenge, { challenge })
    console.log('TNT beginCreating... comitting contact', contact)
    commit(mutationNames.setCurrentPartnerContact, { contact })

    if (addToCache) {
      await dispatch(actionNames.cache, { challenge: challenge, contact: contact })
    }

    return true
  },
  async loadRunningOrderProducts(
    { commit },
    { typeLeagueID, startDate }: { typeLeagueID: string; startDate: Date }
  ): Promise<boolean> {
    const result = await upwardRunningOrderClient.getRunningOrderProductList(typeLeagueID, startDate)
    commit(mutationNames.setRunningOrderProducts, { products: result })
    return true
  },
  async loadRunningParticipants({ commit, state }, { upwardRunningId }) {
    try {
      if (state.lastLeagueLoaded != upwardRunningId) {
        commit('setLoading', true)
        const result = await upwardRunningClient.retrieveParticipants(upwardRunningId)

        commit(mutationNames.setParticipants, { items: result.data })
      }
    } catch (ex) {
      throwError('Error with the retrieval of participants', commit, ex)
    } finally {
      commit('setLoading', false)
    }
  },
  setCurrentParticipantByID(context, { id }: { id: PlayerInfoIDType }) {
    const { state, commit } = pactionContext(context)
    try {
      if (!state.participants) {
        throwError("Can't find an ID on an empty list of participants", context.commit)
        return
      }
      // do we already have it as current item?
      if (
        state.participantUnderEditId == id &&
        state.currentParticipant &&
        state.currentParticipant.individualID == id
      ) {
        return
      }

      const index = state.participants.findIndex((y) => y.individualID == id)
      if (index >= 0) {
        commit.setCurrentParticipant({ participant: state.participants[index] })
        return
      }
    } catch (ex) {
      commit.clearCurrentParticipant()
      throwError(ex.message, context.commit, ex)
    }
    throwError(`Participant not current in edit ${id}`, context.commit)
  },
})

export const namespace = 'upwardRunning'

export const upwardRunning = {
  namespaced: true as true,
  state: upwardRunningState,
  getters: getterTree,
  mutations,
  actions,
}
const pactionContext = (context: any) => moduleActionContext(context, upwardRunning)
