























































import { defineComponent, ref, onBeforeMount } from '@vue/composition-api'
import { AgGridVue } from '@ag-grid-community/vue'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import DateTimeInput from '@/elements/DateTimeInput.vue'
import loading from '@/elements/Loading.vue'
import { startCase } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { convertRegion } from '@/lib/support/utilities/address/stateAbbreviations'

//import store from '@/store'
import upwardRunningClient from '@/clients/upwardRunningClient'

export default defineComponent({
  name: 'StripeBalanceTransactionPanel',
  components: {
    AgGridVue,
    loading,
    DateTimeInput,
  },
  setup() {
    const isLoading = ref(false)
    const rowData = ref<any>(null)
    const gridOptions = ref<any>(null)
    const api = ref<any>(null)
    const columnApi = ref<any>(null)
    const modules = ref<any>(AllCommunityModules)

    const startDate = ref<Date>(dayjs().subtract(2, 'day').startOf('day').toDate())
    const endDate = ref<Date>(dayjs().subtract(2, 'day').endOf('day').toDate())

    dayjs.extend(utc)

    function dateFormatter(params: any) {
      return params.value
        ? `${dayjs.utc(params.value).format('MM/DD/YYYY hh:mm A')} (${dayjs(params.value).format('hh:mm A')})`
        : ''
    }

    function dateCsvFormatter(params: any) {
      return params.value ? `${dayjs.utc(params.value).format('YYYY-MM-DD HH:mm')}` : ''
    }

    const intlNumberFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      signDisplay: 'never',
    })

    function moneyFormatter(params: any) {
      const baseValue = params.value ? intlNumberFormatter.format(params.value) : ''
      return params.value < 0 ? `(${baseValue})` : baseValue
    }

    function moneyClassSelector(params: any) {
      return params.value && params.value < 0 ? 'negative-money' : ''
    }

    function warningFormatter(params: any) {
      return params.data.errorMessage ? `  ${params.value}` : params.value
    }

    function warningClassSelector(params: any) {
      return params.data.errorMessage ? 'fas fa-exclamation-triangle negative-money' : ''
    }

    function stateFormatter(params: any) {
      return convertRegion(params.value, 2)
    }

    onBeforeMount(() => {
      gridOptions.value = {}
    })

    function onReady(params: any) {
      api.value = params.api
      columnApi.value = params.columnApi
      rowData.value = []
    }

    function exportGrid() {
      api.value.exportDataAsCsv()
    }

    const columnDefs: any = {
      type: {
        cellClass: warningClassSelector,
        valueFormatter: warningFormatter,
      },
      created: {
        valueFormatter: dateFormatter,
      },
      amount: {
        valueFormatter: moneyFormatter,
        cellClass: moneyClassSelector,
      },
      fees: {
        valueFormatter: moneyFormatter,
        cellClass: moneyClassSelector,
      },
      net: {
        valueFormatter: moneyFormatter,
        cellClass: moneyClassSelector,
      },
      tax: {
        valueFormatter: moneyFormatter,
        cellClass: moneyClassSelector,
      },
      revenue: {
        valueFormatter: moneyFormatter,
        cellClass: moneyClassSelector,
      },
      owedToChurch: {
        valueFormatter: moneyFormatter,
        cellClass: moneyClassSelector,
      },
      state: {
        valueFormatter: stateFormatter,
      },
    }

    function getColumnDefs(name: string) {
      return columnDefs[name] ?? {}
    }

    async function loadReport() {
      isLoading.value = true

      let columns = []

      try {
        let dataSource = await upwardRunningClient.retrieveBalanceTransactionReport({
          //TODO link these to controls
          startDate: startDate.value,
          endDate: endDate.value,
          limit: 0,
        })
        if (!dataSource) {
          dataSource = []
        }

        if (dataSource.length > 0) {
          var fieldNames = Object.keys(dataSource[0])
          //var defColDefs = getDefaultColDefs()
          for (var name of fieldNames) {
            columns.push({
              field: name,
              headerName: startCase(name),
              ...{}, //...[defColDefs],
              ...getColumnDefs(name),
            })
          }
        }

        rowData.value = dataSource
        // need to clear columns before setting columns
        api.value.setColumnDefs([])
        api.value.setColumnDefs(columns)
        api.value.setSortModel([]) //(sortModel.value)
      } catch (err) {
        rowData.value = []
      }
      isLoading.value = false
    }

    function onFirstDataRendered() {
      if (rowData.value && rowData.value.length > 0) {
        columnApi.value.autoSizeColumns(Object.keys(rowData.value[0]), false)
      }
    }

    function downloadCsv() {
      const parms = { processCellCallback: csvProcessCellCallback }
      api.value.exportDataAsCsv(parms)
    }

    function csvProcessCellCallback(params: any) {
      switch (params.column.getColId()) {
        case 'created':
          return dateCsvFormatter(params)
        case 'state':
          return stateFormatter(params)
        default:
          return params.value
      }
    }

    return {
      loadReport,
      downloadCsv,
      isLoading,
      exportGrid,
      gridOptions,
      rowData,
      modules,
      onReady,
      startDate,
      endDate,
      onFirstDataRendered,
    }
  },
})
